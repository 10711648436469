import BasePlugin from '../BasePlugin'

export default class UpdateDecisionRequests extends BasePlugin {
  async execute () {
    let decisionId = this.context.getModel().id
    this.context.getCard().setLoading(true)
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/UpdateDecisionRequestsCommand`,
      { 'decision_id': decisionId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getCard().setLoading(false)
      this.context.$msgbox({
        type: 'info',
        message: 'Обновление проекта решения успешно завершено'
      })
    })
  }
}
